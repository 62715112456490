<template>
  <div>
    <PageHeader :items="items" :optionalItems="optionalItems" />
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <div class="d-flex align-items-center">
          <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
          <b-form-select
            v-model="pageLength"
            :options="['25', '50', '100']"
            class="ml-1"
            @input="(value) => onPerPageChange({ pageLength: value })"
          />
        </div>
      </b-form-group>
    </div>
    <div class="table-responsive api-keys-table">
      <!-- table -->
      <vue-good-table
        styleClass="vgt-table striped"
        :columns="columns"
        :fixed-header="false"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
          trigger: 'enter',
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-per-page-change="onPerPageChange"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->

          <span
            class="d-flex align-items-center justify-content-center gap-2"
            v-if="props.column.field === 'action'"
          >
            <div
              v-if="$can(`${$route.meta.permission}.edit`)"
              class="cursor-pointer"
              @click="
                $router.push(`/invoice-reminder-levels/${props.row.id}/edit`)
              "
            >
              <feather-icon size="16" icon="Edit2Icon" />
            </div>
            <div
              v-if="$can(`${$route.meta.permission}.delete`)"
              class="cursor-pointer"
              @click="destroy(props.row.id)"
            >
              <feather-icon size="16" icon="TrashIcon" />
            </div>
          </span>

          <span v-else-if="props.column.field === 'reminderFee'">
            {{
              $formatter(
                props.row.reminderFee,
                $i18n.locale,
                "EUR",
                false,
                2,
                2
              )
            }}
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-end flex-wrap">
            <div>
              <b-pagination
                :value="1"
                :total-rows="totalRecords"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => onPageChange({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { mapGetters } from "vuex";
import NotificationService from "../../services/notification.service";
import Multiselect from "vue-multiselect";

export default {
  components: {
    PageHeader,
    Multiselect,
  },
  computed: {
    optionalItems() {
      return {
        createBtn1: {
          show: true,
          text: this.$t("Create Reminder Level"),
          icon: "PlusIcon",
          path: "/invoice-reminder-levels/create",
          permission: "invoice-reminder-level.create",
        },
      };
    },
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Reminder Levels"),
          active: true,
        },
      ];
    },
    columns() {
      return [
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Level Name"),
          field: "levelName",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Period Days"),
          field: "periodDays",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Reminder Fee"),
          field: "reminderFee",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Action"),
          field: "action",
          sortable: false,
        },
      ];
    },
  },
  data() {
    return {
      totalRecords: 0,
      pageLength: 25,
      page: 1,
      form: {
        sortBy: "createdAt",
        sortOrder: "desc",
      },
      rows: [],
      searchTerm: "",
    };
  },

  async created() {
    this.loadItems();
  },

  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },

    onSortChange(params) {
      this.form.sortOrder = params[0].type;
      if (params[0].type == "none") this.form.sortOrder = "asc";
      this.form.sortBy = params[0].field;
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.$store.commit("showLoader", true);
        let response = await this.$store.dispatch("invoiceReminderLevel/list", {
          page: this.page,
          ...this.form,
          perPage: this.pageLength,
        });
        this.rows = response?.data?.data;

        this.totalRecords = response?.data?.total;
      } catch (e) {
        console.error("api error ___", e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    async destroy(id) {
      this.$swal({
        title: this.$t("Do you want to delete this record?"),
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes delete it!"),
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          await this.$store
            .dispatch("invoiceReminderLevel/destroy", id)
            .finally(() => {
              this.loadItems();
            });
        }
      });
    },
  },
};
</script>

<style>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
